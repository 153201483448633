import http from '@/utils/http'

/**
 * 获取用户转移记录
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getList = params => http.get('/user/migrate/log/list', { params: params })

/**
 * 账号转移申请
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const apply = params => http.post('/user/migrate/log/migrate', params)

/**
 * 账号转移审核
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const audit = params => http.post('/user/migrate/log/audit', params)
