import http from '@/utils/http'
import httpBasic from '@/utils/http-basic'

export const getList = params => http.get('/ams/account/balance/list', { params: params })

export const exportExcel = params => {
  return httpBasic({
    url: '/ams/account/balance/export/excel',
    method: 'post',
    data: params,
    responseType: 'blob'
  })
}
