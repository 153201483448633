import Vue from 'vue'

import ElementUI from 'element-ui'
import App from './App.vue'
import router from './router'
import store from './store'
import permission from './directive/permission'
import VueVideoPlayer from 'vue-video-player'
import VueDraggableResizable from 'vue-draggable-resizable'
import AppInstall from '@/utils/app-install'
import VueClipboard from 'vue-clipboard2'

// js
import '@/utils/permission'
import 'tinymce/tinymce.min'
import '@/components'

// css
import './assets/ele/custom/index.css'
import './assets/styles/index.less'
import 'video.js/dist/video-js.css'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'

Vue.component('vue-draggable-resizable', VueDraggableResizable)

Vue.config.productionTip = false
Vue.use(ElementUI, { size: 'small' })
Vue.use(VueVideoPlayer)

Vue.use(AppInstall)
Vue.directive('perm', permission)

Vue.use(VueClipboard)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
