<template>
  <el-aside :width="isCollapse ? '64px': '208px'">
    <el-menu
        router
        class="layout-menu"
        :default-active="activePath"
        :collapse="isCollapse"
        :collapse-transition="false">
      <el-menu-item index="/dashboard">
        <template slot="title">
          <div class="title-row">
            <div class="menu-icon">
              <i class="fa-solid fa-gauge"></i>
            </div>
            <div class="menu-title">
              <span>工作台</span>
            </div>
          </div>
        </template>
      </el-menu-item>
      <template v-for="menu in menus">
        <el-submenu :index="menu.id" :key="menu.id" v-if="menu.child.length > 0">
          <template slot="title">
            <div class="title-row">
              <div class="menu-icon">
                <i :class="menu.icon"></i>
              </div>
              <div class="menu-title">
                <span>{{ menu.title }}</span>
              </div>
            </div>
          </template>
          <el-menu-item class="el-menu-item-sub" :key="item.id" v-for="item in menu.child" :index="item.path">
            {{ item.title }}
          </el-menu-item>
        </el-submenu>
        <el-menu-item v-else :key="menu.id" :index="menu.path">
          <template slot="title">
            <div class="title-row">
              <div class="menu-icon">
                <i :class="menu.icon"></i>
              </div>
              <div class="menu-title">
                <span>{{ menu.title }}</span>
              </div>
            </div>
          </template>
        </el-menu-item>
      </template>
    </el-menu>
  </el-aside>
</template>
<script>

export default {
  name: 'AppMenu',
  computed: {
    menus () {
      return this.$store.getters.menus || []
    },
    activePath () {
      return this.$route.path
    },
    isCollapse () {
      return this.$store.getters.menuIsCollapse
    }
  },
  data () {
    return {}
  },
  methods: {
    handleMenuSwitch () {
      this.$store.dispatch('toggleMenuIsCollapse')
    }
  },
  mounted () {
  }
}
</script>

<style lang="less">

</style>
