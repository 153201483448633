import http from '@/utils/http'

export const getList = () => http.get('/ams/resource/tree')

export const get = id => http.get(`/ams/resource/item?id=${id}`)

export const save = params => http.post('/ams/resource/save', params)

export const remove = params => http.post('/ams/resource/remove', params)

export const getTreeByPid = params => http.get('/ams/resource/tree', { params: params })

export const getAll = () => http.get('/ams/resource/all')
