import http from '@/utils/http'

export const getList = params => http.get('/ams/role/list', { params: params })

export const getAll = () => http.get('/ams/role/all')

export const get = id => http.get(`/ams/role/item?id=${id}`)

export const save = params => http.post('/ams/role/save', params)

export const remove = params => http.post('/ams/role/remove', params)

export const getResource = id => http.get(`/ams/role/resource?id=${id}`)

export const saveResource = params => http.post('/ams/role/save/resource', params)
