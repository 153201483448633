import Vue from 'vue'

import CategorySelect from './CategorySelect'
import DictSelect from './DictSelect'
import DictText from './DictText'
import DynamicTag from './DynamicTag'
import PageHeader from './PageHeader'
import RemoteSelect from './RemoteSelect'
import SearchSelect from './SearchSelect'
import VideoUpload from './VideoUpload'

Vue.component('category-select', CategorySelect)
Vue.component('dict-select', DictSelect)
Vue.component('dict-text', DictText)
Vue.component('dynamic-tag', DynamicTag)
Vue.component('page-header', PageHeader)
Vue.component('remote-select', RemoteSelect)
Vue.component('search-select', SearchSelect)
Vue.component('video-upload', VideoUpload)
