<template>
  <el-select
      v-model="currentValue"
      filterable
      remote
      :value-key="valueKey"
      :clearable="clearable"
      :placeholder="placeholder"
      :remote-method="handleSearch"
      @change="handleChange"
      @focus="handleFocus"
      :style="{'width': width}"
      :loading="loading">
    <el-option
        v-for="item in rows"
        :key="item[columns.value]"
        :label="item[columns.label]"
        :value="item">
    </el-option>
    <i slot="prefix" class="el-input__icon el-icon-search"></i>
  </el-select>
</template>

<script>

import http from '@/utils/http'

/**
 * 联想控件
 */
export default {
  name: 'SearchSelect',
  props: {
    value: {
      required: true
    },
    clearable: {
      type: Boolean,
      default: false
    },
    placeholder: String,
    columns: {
      type: Object,
      default: function () {
        return {
          label: 'label',
          value: 'value'
        }
      }
    },
    url: {
      type: String,
      required: true
    },
    valueKey: {
      type: String,
      default: 'id'
    },
    width: {
      type: String
    }
  },
  data () {
    return {
      currentValue: '',
      loading: false,
      rows: [],
      timer: null,
      defaultParams: {
        page: 0,
        size: 10
      }
    }
  },
  methods: {
    async getData (params) {
      const res = await http.get(this.url, { params: params })
      if (res && Object.keys(res).some(it => it === 'rows')) {
        this.rows = res.rows
      } else {
        this.rows = res
      }
    },
    handleSearch (query) {
      if (this.timer != null) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(async () => {
        this.loading = true
        const params = {
          ...this.defaultParams,
          text: query
        }
        await this.getData(params)
        this.loading = false
      }, 200)
    },
    handleChange (val) {
      this.$emit('input', val[this.valueKey])
      this.$emit('change', val)
    },
    handleFocus () {
      if (this.rows.length === 0) {
        this.handleSearch()
      }
    },
    async initData () {
      if (this.valueKey && this.value) {
        const params = {
          ...this.defaultParams
        }
        params[this.valueKey] = this.value
        await this.getData(params)
        this.currentValue = this.rows[0]
        // this.handleChange(current)
      }
    }
  },
  mounted () {
    this.initData()
  }
}
</script>

<style scoped>
</style>
