const app = {
  state: {
    menuIsCollapse: false
  },
  mutations: {
    setMenuIsCollapse: (state) => {
      state.menuIsCollapse = !state.menuIsCollapse
    }
  },
  actions: {
    toggleMenuIsCollapse ({ commit }) {
      commit('setMenuIsCollapse')
    }
  }
}

export default app
