import http from '@/utils/http'

// 设置交易密码
export const saveDealPassword = params => http.post('/ams/account/set/deal/password', params)

// 验证交易密码
export const validDealPassword = params => http.post('/ams/account/validate/password', params)

// 发送邮箱验证码
export const sendEmailCode = params => http.get('/ams/account/send/email', params)

// 邮箱验证码验证
export const validEmailCode = params => http.post('/ams/account/validate/code', params)

// 获取当前帐户信息
export const getAccount = () => http.get('/ams/account/current')
