<template>
  <div>
    <el-popover
        :placement="placement"
        :width="width"
        :disabled="readonly"
        v-model="visible">
      <div>
        <el-row>
          <el-col :span="24">
            <el-input v-model="searchForm.text" placeholder="输入关键字过滤" style="width: 100%"
                      @input="handleKeyup"></el-input>
          </el-col>
        </el-row>
        <el-row style="margin-top: 10px">
          <el-col :span="24">
            <el-table
                :data="rows"
                v-loading="loading"
                border
                highlight-current-row
                size="mini"
                ref="singleTable"
                @current-change="handleSelect"
                style="width: 100%">
              <el-table-column
                  align="center"
                  v-for="c in columns"
                  :key="c.field"
                  :prop="c.field"
                  :label="c.name"></el-table-column>
            </el-table>
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-size="size"
                :background="true"
                align="center"
                layout="total, prev, next"
                style="margin-top: 10px"
                :total="total">
            </el-pagination>
          </el-col>
        </el-row>
      </div>

      <el-input slot="reference" v-model="selectRow[label]"
                :style="{'width': widthInput}"
                :class="{'input-disable': readonly}"
                :clearable="clearable"
                @focus="handleFocus"
                @input="handleInputChange"
                :placeholder="placeholder"
                :readonly="readonly"
                @clear="handleInputClear"></el-input>
    </el-popover>
  </div>
</template>

<script>
import http from '@/utils/http'

export default {
  name: 'RemoteSelect',
  props: {
    value: {
      type: String
    },
    width: {
      type: String,
      default: '350'
    },
    widthInput: {
      type: String
    },
    columns: Array,
    url: String,
    label: String,
    valueKey: {
      type: String,
      default: 'id'
    },
    placement: {
      type: String,
      default: 'bottom-start'
    },
    clearable: {
      type: Boolean,
      default: false
    },
    size: {
      type: Number,
      default: 10
    },
    placeholder: String,
    readonly: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value: {
      handler (newVal, oldVal) {
        this.searchForm[this.valueKey] = newVal
        this.initData()
      },
      immediate: true
    }
  },
  data () {
    return {
      searchForm: {
        text: ''
      },
      rows: [],
      page: 1,
      total: 0,
      loading: false,
      visible: false,
      selectRow: {},
      timer: null
    }
  },
  methods: {
    search () {
      if (this.timer != null) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        this.loading = true
        const params = {
          page: this.page - 1,
          size: this.size
        }
        if (this.searchForm.text) {
          params.text = this.searchForm.text
        }
        if (this.searchForm[this.valueKey]) {
          params[this.valueKey] = this.searchForm[this.valueKey]
        }
        http.get(this.url, { params: params }).then(result => {
          this.rows = result.rows
          this.total = result.total
          this.loading = false
          if (params[this.valueKey]) {
            const row = this.rows.find(r => { return r[this.valueKey] === params[this.valueKey] })
            if (row) {
              this.$refs.singleTable.setCurrentRow(row)
            }
          } else {
            this.$refs.singleTable.setCurrentRow()
            this.selectRow = {}
          }
        })
      }, 400)
    },
    handleSizeChange (size) {
      this.size = size
      this.search()
    },
    handleCurrentChange (page) {
      this.page = page
      this.search()
    },
    handleKeyup () {
      if (this.timer != null) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        this.search()
      }, 500)
    },
    handleFocus () {
      if (this.rows.length === 0) {
        this.search()
      }
    },
    handleSelect (row) {
      if (row && this.selectRow[this.valueKey] !== row[this.valueKey]) {
        this.selectRow = { ...row }
        this.visible = false
        this.$emit('input', row[this.valueKey])
        this.$emit('change', row)
      }
    },
    handleInputChange (val) {
      if (!val) {
        this.$refs.singleTable.setCurrentRow()
        this.selectRow = {}
        this.$emit('input', null)
        this.$emit('change', null)
      }
    },
    handleInputClear () {
      this.$refs.singleTable.setCurrentRow()
      this.selectRow = {}
      this.$emit('input', null)
      this.$emit('change', null)
    },
    initData () {
      this.search()
    }
  }
}
</script>

<style scoped lang="less">
.row-active {
  background-color: #ecf4f8;
}

.input-disable {
  /deep/ input {
    background-color: #f5f7fa;
    border-color: #e4e7ed;
    color: #c0c4cc;
  }
}
</style>
