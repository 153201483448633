import http from '@/utils/http'

export const list = params => http.get('/cms/material/poster/list', { params: params })

export const remove = params => http.post('/cms/material/poster/remove', params)

export const publish = params => http.post('/cms/material/poster/publish', params)

export const out = params => http.post('/cms/material/poster/out', params)

export const savePoster = params => http.post('/cms/material/poster/save', params)

export const getPoster = id => http.get(`/cms/material/poster/item?id=${id}`)
