import http from '@/utils/http'

export const list = params => http.get('/cms/material/article/list', { params: params })

export const remove = params => http.post('/cms/material/article/remove', params)

export const publish = params => http.post('/cms/material/article/publish', params)

export const out = params => http.post('/cms/material/article/out', params)

export const saveArticle = params => http.post('/cms/material/article/save', params)

export const getArticle = id => http.get(`/cms/material/article/item?id=${id}`)

export const parseContent = id => http.get(`/cms/material/article/parse?id=${id}`)

export const importArticle = params => http.post('/material/article/parse', params)
