<template>
  <el-select clearable
             :placeholder="placeholder"
             v-model="currentValue"
             @change="handleChange">
    <el-option
        v-for="item in options"
        :key="item.code"
        :label="item.name"
        :value="item.code">
    </el-option>
  </el-select>
</template>

<script>
import Dictionary from '@/utils/dictionary'

export default {
  name: 'DictSelect',
  props: {
    value: {
      type: [String, Number]
    },
    placeholder: String,
    dictName: String
  },
  data () {
    return {
      currentValue: ''
    }
  },
  computed: {
    options () {
      return Dictionary.list(this.dictName)
    }
  },
  methods: {
    handleChange (val) {
      this.$emit('input', val)
      this.$emit('change', val)
    }
  }
}
</script>

<style scoped lang="less">

</style>
