const getters = {
  perms: state => state.user.perms,
  realName: state => state.user.info.displayName,
  orgName: state => state.user.info.creatorOrgName,
  userId: state => state.user.info.id,
  orgId: state => state.user.info.creatorOrgId,
  userName: state => state.user.info.username,
  resources: state => state.user.resources,
  menus: state => state.user.menus,
  routers: state => state.user.routers,
  menuIsCollapse: state => state.app.menuIsCollapse,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews
}
export default getters
