import http from '@/utils/http'

export const getList = params => http.get('/ams/dept/list', { params: params })

export const getAll = () => http.get('/ams/dept/all')

export const get = id => http.get(`/ams/dept/item?id=${id}`)

export const save = params => http.post('/ams/dept/save', params)

export const remove = params => http.post('/ams/dept/remove', params)
