<template>
    <router-view></router-view>
</template>
<script type="text/javascript">
export default {
  name: 'App'
}
</script>
<style>
</style>
