import http from '@/utils/http'

// 创建订单
export const createOrder = params => http.post('/ams/order/create', params)

export const cancelOrder = params => http.post('/ams/order/cancel', params)

export const getOrderInfo = id => http.get(`/ams/order/get/${id}`)

export const getOrderPayQrCode = params => http.post('/pay/wx/qrcode', params)

export const getOrderList = params => http.get('/ams/order/list', { params: params })

export const payOrderByBalance = params => http.post('/pay/balance', params)
