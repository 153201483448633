<template>
  <el-main class="layout-main">
    <router-view>
    </router-view>
  </el-main>
</template>
<script>
export default {
  name: 'AppMain'
}
</script>

<style lang="less" scoped>

</style>
