export const SHORTCUTS = [{
  text: '今天',
  onClick (picker) {
    const end = new Date()
    const start = new Date()
    picker.$emit('pick', [start, end])
  }
}, {
  text: '昨天',
  onClick (picker) {
    const date = new Date()
    date.setTime(date.getTime() - 3600 * 1000 * 24)
    picker.$emit('pick', [date, date])
  }
}, {
  text: '最近一周',
  onClick (picker) {
    const end = new Date()
    const start = new Date()
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
    picker.$emit('pick', [start, end])
  }
}, {
  text: '最近一个月',
  onClick (picker) {
    const end = new Date()
    const start = new Date()
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
    picker.$emit('pick', [start, end])
  }
}, {
  text: '最近三个月',
  onClick (picker) {
    const end = new Date()
    const start = new Date()
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
    picker.$emit('pick', [start, end])
  }
}]

export const TOKEN_NAME = 'Skt-Authorization'

export const getLabelName = (rows, val) => {
  const row = rows.find(it => it.value === val)
  return row && row.label
}

export const CATEGORY_TYPE = {
  /**
   * 内容分类
   */
  content: 1,
  /**
   * 发圈素材分类
   */
  moments: 2
}

export const STATUS = {
  normal: 1,
  disabled: 2
}

export const MATERIAL_TYPE = {
  article: 1,
  video: 2,
  poster: 3
}

export const MATERIAL_STATUS = {
  saved: 1,
  publish: 2,
  out: 3,
  getText (val) {
    if (val === MATERIAL_STATUS.saved) {
      return '未发布'
    } else if (val === MATERIAL_STATUS.publish) {
      return '已发布'
    } else if (val === MATERIAL_STATUS.out) {
      return '已下架'
    }
  }
}
export const MATERIAL_SOURCE = {
  original: 1,
  reprint: 2
}

export const FILE_GROUP = {
  /**
   * 头像
   */
  avatar: 'avatar',
  /**
   * 二维码
   */
  qr: 'qr',
  /**
   * 文章封面
   */
  article_cover: 'article_cover',
  /**
   * 视频封面
   */
  video_cover: 'video_cover',
  /**
   * 视频
   */
  video: 'video',
  /**
   * 海报
   */
  poster: 'poster',
  /**
   * 轮播图
   */
  slide: 'slide'
}

export const AD_TYPE = {
  wap: 1,
  pc: 2,
  app: 3
}

export const AD_TYPE_LIST = [
  {
    label: 'H5',
    value: 1
  }
]

export const PLACE = {
  WAP_HOME: 101,
  WAP_ARTICLE: 102,
  WAP_USER: 103,
  getText: function (val) {
    if (PLACE.WAP_HOME === val) {
      return '首页'
    } else if (PLACE.WAP_ARTICLE === val) {
      return '文章内容页'
    } else if (PLACE.WAP_USER === val) {
      return '个人中心首页'
    } else {
      return '未知'
    }
  }
}

export const PLACE_LIST = [
  {
    label: '首页',
    value: 101
  },
  {
    label: '文章内容页',
    value: 102
  },
  {
    label: '个人中心首页',
    value: 103
  }
]

export const SEX = {
  MAN: 1,
  WOMAN: 2,
  getText: function (sex) {
    if (SEX.MAN === sex) {
      return '男'
    } else if (SEX.WOMAN === sex) {
      return '女'
    } else {
      return '未知'
    }
  }
}

export const UserVideoTplType = {
  WATERMARK: 1,
  VIDEO_START: 2,
  VIDEO_END: 3,
  getText: function (val) {
    if (UserVideoTplType.WATERMARK === val) {
      return '水印图片'
    } else if (UserVideoTplType.VIDEO_START === val) {
      return '视频片头'
    } else if (UserVideoTplType.VIDEO_END === val) {
      return '视频片尾'
    }
  }
}

export const ORDER_SOURCE = [
  {
    label: '用户创建',
    value: 1
  },
  {
    label: '系统创建',
    value: 2
  }
]

export const ORDER_STATUS = [
  {
    label: '待付款',
    value: 10
  },
  {
    label: '已取消',
    value: 20
  },
  {
    label: '已完成',
    value: 80
  }
]

export const PAY_STATUS = [
  {
    label: '未支付',
    value: 1
  },
  {
    label: '已支付',
    value: 2
  }
]

export const PAY_WAY_ENUM = {
  WX: 1,
  OFFLINE: 2,
  OTHER: 4
}

export const PAY_WAY = [
  {
    label: '微信支付',
    value: 1
  },
  {
    label: '线下付款',
    value: 2
  },
  {
    label: '其它',
    value: 4
  }
]

export const GOODS_STATUS = {
  add: 1,
  publish: 2,
  out: 3
}

export const ORDER_TYPE = [
  {
    label: 'VIP',
    value: 1
  },
  {
    label: '充值包',
    value: 2
  }
]

export const VIP_OPEN_TYPE = {
  FIRST_OPEN: 1,
  RE_OPEN: 2
}

export const VIP_OPEN_TYPE_LIST = [
  {
    label: '首次开通',
    value: 1
  },
  {
    label: '续费',
    value: 2
  }
]

export const ROLE_DATA_SCOPE = [
  {
    label: '全部',
    value: 1
  },
  {
    label: '部门',
    value: 2
  },
  {
    label: '自己',
    value: 3
  }
]

export const PARSER_TYPE = [
  {
    label: 'SOQI',
    value: 'SOQI'
  },
  {
    label: 'WEIXIN',
    value: 'WEIXIN'
  }
]
