<template>
  <div class="layout-admin">
    <el-container direction="vertical">
      <app-header></app-header>
      <el-container>
        <app-menu></app-menu>
        <app-main></app-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import AppMenu from './components/AppMenu'
import AppHeader from './components/AppHeader'
import AppMain from './components/AppMain'

export default {
  name: 'Main',
  components: {
    AppMenu,
    AppHeader,
    AppMain
  },
  mounted () {
  }
}
</script>

<style lang="less">

</style>
