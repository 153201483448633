import http from '@/utils/http'
import httpBasic from '@/utils/http-basic'

export const list = params => http.get('/invoice/list', { params: params })

export const save = params => http.post('/invoice/save', params)

export const ticket = params => http.put('/invoice/ticket', params)

export const send = id => http.put(`/invoice/send/${id}`)

export const download = params => {
  return httpBasic({
    url: '/invoice/download',
    method: 'post',
    data: params,
    responseType: 'blob'
  })
}
export const excel = params => {
  return httpBasic({
    url: '/invoice/export/excel',
    method: 'post',
    data: params,
    responseType: 'blob'
  })
}
