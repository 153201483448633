<template>
  <div>
    <el-row>
      <el-col>
        <el-alert type="warning" :closable="false" style="margin-bottom: 10px">
          <template slot="title">
            视频格式要求: 文件格式：mp4, 尺寸：720x1280, 帧率：30
          </template>
        </el-alert>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="6">
        <div>
          <el-upload
              class="upload-demo"
              action="#"
              :multiple="false"
              :before-upload="handleBeforeUpload"
              :limit="1">
            <el-button size="small" plain icon="el-icon-upload" :loading="loading">上传视频</el-button>
          </el-upload>
        </div>
        <div class="video-wrapper">
          <video v-if="file.video"
                 :src="file.video.fileUrl"
                 ref="myVideo"
                 crossOrigin="anonymous"
                 controls="controls" :style="{'width': width}"
                 preload="auto"></video>
        </div>
      </el-col>
      <el-col :span="6">
        <div v-if="file.video">
          <div class="cover-label">视频封面(点击图片可修改封面)</div>
          <div class="cover-wrapper">
            <el-upload
                class="avatar-uploader"
                action="#"
                :show-file-list="false"
                :limit="1"
                :before-upload="handleUploadCover">
              <img referrer-policy="No Referrer" v-if="file.cover" :src="file.cover.fileUrl" :style="{'width': width}"/>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>

import { CommonQiniuApi } from '@/api'

export default {
  name: 'VideoUpload',
  props: {
    value: Object,
    name: String,
    width: String
  },
  watch: {
    value: {
      handler (val) {
        if (val) {
          this.file = {
            cover: { ...val.cover },
            video: { ...val.video }
          }
        } else {
          this.file = {
            cover: null,
            video: null
          }
        }
      },
      immediate: true
    }
  },
  data () {
    return {
      file: {
        cover: null,
        video: null,
        videoMeta: null
      },
      loading: false
    }
  },
  methods: {
    handleChange (val) {
      const current = {
        cover: { ...val.cover },
        video: { ...val.video },
        videoMeta: { ...val.videoMeta }
      }
      this.$emit('input', current)
      this.$emit('change', current)
    },
    handleBeforeUpload (file) {
      this.loading = true
      const data = new FormData()
      data.append('file', file)
      CommonQiniuApi.uploadVideo(data).then((res) => {
        this.file.videoMeta = res.videoMeta
        this.file.video = res.video
        this.file.cover = res.cover
        this.loading = false
        this.handleChange(this.file)
      }).catch(() => {
        this.loading = false
      })
      return false
    },
    handleUploadCover (file) {
      const data = new FormData()
      data.append('file', file)
      CommonQiniuApi.upload(data).then((res) => {
        this.file.cover = res
        this.handleChange(this.file)
      }).catch(() => {
        this.loading = false
      })
      return false
    }
  }
}
</script>

<style lang="less">
.video-wrapper {
  margin-top: 10px;

  video {
    width: 200px;
  }
}

.cover-wrapper {
  margin-top: 10px;

  img {
    width: 200px;
  }
}

.cover-label {
  line-height: 32px;
  height: 32px;
}
</style>
