import http from '@/utils/http'

export const get = id => http.get(`/cms/category/item?id=${id}`)

export const save = params => http.post('/cms/category/save', params)

export const remove = params => http.post('/cms/category/remove', params)

export const list = params => http.get('/cms/category/list', { params: params })

export const all = type => http.get(`/cms/category/all?type=${type}`)
