const TOKEN_NAME = 'Authorization'

function getToken () {
  const token = localStorage.getItem(TOKEN_NAME)
  return token != null ? token.toString() : ''
}

function setToken (token) {
  localStorage.setItem(TOKEN_NAME, token)
}

function removeToken () {
  localStorage.removeItem(TOKEN_NAME)
}

export { getToken, setToken, removeToken }
