// 标记类型
const BADGE_TYPE = {
  default: 'default',
  success: 'success',
  danger: 'danger',
  none: 'none'
}

class Dictionary {
  constructor (name, code, type = BADGE_TYPE.default) {
    this.name = name
    this.code = code
    this.type = type
  }
}

export default {
  BADGE_TYPE,
  list (name) {
    const dict = this[name]
    const result = []
    for (const key of Object.keys(dict)) {
      result.push(dict[key])
    }
    return result
  },
  // 代理商状态
  orgStatus: {
    add: new Dictionary('新申请', 1, BADGE_TYPE.default),
    normal: new Dictionary('正常', 2, BADGE_TYPE.success),
    type: new Dictionary('禁用', 3, BADGE_TYPE.danger)
  },
  // 代理商订单状态
  amsOrderStatus: {
    add: new Dictionary('待支付', 10, BADGE_TYPE.danger),
    cancel: new Dictionary('已取消', 20, BADGE_TYPE.default),
    done: new Dictionary('已完成', 80, BADGE_TYPE.success)
  },
  // 代理商订单状态(充值订单)
  amsOrderStatusRecharge: {
    add: new Dictionary('待确认', 10, BADGE_TYPE.danger),
    cancel: new Dictionary('已取消', 20, BADGE_TYPE.default),
    done: new Dictionary('已完成', 80, BADGE_TYPE.success)
  },
  // 代理商订单支付方式
  amsPayWay: {
    wechat: new Dictionary('微信支付', 1, BADGE_TYPE.none),
    balance: new Dictionary('余额支付', 2, BADGE_TYPE.none),
    remittance: new Dictionary('对公汇款', 3, BADGE_TYPE.none)
  },
  // 订单类型
  amsOrderType: {
    rechargeMoney: new Dictionary('充值', 1, BADGE_TYPE.none),
    rechargeVipCard: new Dictionary('购买VIP年卡', 2, BADGE_TYPE.none),
    remittance: new Dictionary('对公汇款', 3, BADGE_TYPE.none)
  },
  // 订单归属
  amsOrderOwner: {
    agent: new Dictionary('代理商', 1, BADGE_TYPE.none),
    dealer: new Dictionary('经销商', 2, BADGE_TYPE.none)
  },
  // 代理商订单支付方式
  amsOrderPayStatus: {
    unPay: new Dictionary('未支付', 1, BADGE_TYPE.danger),
    success: new Dictionary('已支付', 2, BADGE_TYPE.success)
  },
  // 代理商 用户类型
  amsUserType: {
    admin: new Dictionary('管理员', 1, BADGE_TYPE.none),
    employee: new Dictionary('员工', 2, BADGE_TYPE.none),
    dealer: new Dictionary('经销商', 3, BADGE_TYPE.none)
  },
  // 员工状态
  amsUserStatus: {
    normal: new Dictionary('正常', 1, BADGE_TYPE.success),
    disabled: new Dictionary('禁用', 2, BADGE_TYPE.danger)
  },
  // 提现状态
  withdrawStatus: {
    commit: new Dictionary('申请中', 1, BADGE_TYPE.default),
    audited: new Dictionary('提现成功', 3, BADGE_TYPE.success),
    reject: new Dictionary('提现驳回', 4, BADGE_TYPE.danger)
  },
  // 用户订单支付方式
  userOrderPayWay: {
    wechat: new Dictionary('微信支付', 1, BADGE_TYPE.none),
    offline: new Dictionary('线下付款', 2, BADGE_TYPE.none),
    other: new Dictionary('其它', 4, BADGE_TYPE.none)
  },
  // 用户VIP开通类型
  userVipType: {
    firstOpen: new Dictionary('首次开通', 1, BADGE_TYPE.none),
    reOpen: new Dictionary('续费', 2, BADGE_TYPE.none)
  },
  // 用户VIP套餐状态
  userVipGoodsCard: {
    add: new Dictionary('新增', 1, BADGE_TYPE.none),
    publish: new Dictionary('已发布', 2, BADGE_TYPE.none),
    out: new Dictionary('已下架', 3, BADGE_TYPE.none)
  },
  // 用户VIP套餐状态
  vipCardLogType: {
    free: new Dictionary('系统赠送', 1, BADGE_TYPE.none),
    payed: new Dictionary('付费购买', 2, BADGE_TYPE.none)
  },
  // 帐户扣减类型
  accountLogType: {
    income: new Dictionary('收入', 1, BADGE_TYPE.success),
    disburse: new Dictionary('支出', 2, BADGE_TYPE.danger)
  },
  // cms 内容分类
  cmsCategoryType: {
    content: new Dictionary('内容分类', 1, BADGE_TYPE.none),
    moments: new Dictionary('素材分类', 2, BADGE_TYPE.none)
  },
  // 素材状态
  cmsMaterialStatus: {
    saved: new Dictionary('未发布', 1, BADGE_TYPE.default),
    publish: new Dictionary('已发布', 2, BADGE_TYPE.success),
    out: new Dictionary('已下架', 3, BADGE_TYPE.danger)
  }
}
