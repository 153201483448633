<template>
  <div>
    <el-cascader
        v-model="selectValue"
        :options="selectOptions"
        :props="selectProps"
        placeholder="分类"
        @change="handleChange"
        :style="{'width':width}"
        clearable></el-cascader>
  </div>
</template>

<script>

import { CmsCategoryApi } from '@/api'
import { CATEGORY_TYPE } from '@/utils/contants'

export default {
  name: 'CategorySelect',
  props: {
    value: [String, Array],
    type: {
      type: Number,
      default: CATEGORY_TYPE.content
    },
    checkStrictly: {
      type: Boolean,
      default: false
    },
    emitPath: {
      type: Boolean,
      default: true
    },
    width: {
      type: String,
      default: '200px'
    }
  },
  watch: {
    value (val) {
      this.selectValue = val
    }
  },
  data () {
    return {
      selectValue: null,
      selectProps: {
        value: 'id',
        label: 'name',
        children: 'children',
        checkStrictly: this.checkStrictly,
        emitPath: this.emitPath
      },
      selectOptions: []
    }
  },
  methods: {
    handleChange (val) {
      this.$emit('input', val)
      this.$emit('change', val)
    }
  },
  activated () {
    CmsCategoryApi.all(this.type).then(data => {
      this.selectOptions = data
    })
  },
  mounted () {
    CmsCategoryApi.all(this.type).then(data => {
      this.selectOptions = data
    })
  }
}
</script>

<style>
</style>
