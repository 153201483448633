<template>
  <div class="page-header">
    <div class="page-title"><i v-if="hasBack" class="el-icon-back" @click="handleGoBack"></i>{{ activePathTitle }}</div>
    <div style="flex: 1 1 0%"></div>
    <div class="right">
      <slot name="right"></slot>
    </div>
  </div>
</template>
<script>

export default {
  name: 'AppPageHeader',
  props: {
    fixed: {
      type: Boolean,
      default: false
    },
    hasBack: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    activePathTitle () {
      return this.$route.meta.title
    }
  },
  methods: {
    handleGoBack () {
      this.$router.back()
    }
  }
}
</script>

<style lang="less" scoped>
.page-header {
  height: 48px;
  display: flex;
  align-items: center;
  background-color: #FFFFFF;
  padding: 0 20px;

  .page-title {
    font-size: 16px;
    font-weight: 600;
  }
}

.el-icon-back {
  margin-right: 10px;
  cursor: pointer;
}

</style>
