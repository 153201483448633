import http from '@/utils/http'

export const getUserList = params => http.get('/ams/user/list', { params: params })

export const getUser = id => http.get(`/ams/user/item?id=${id}`)

export const saveUser = params => http.post('/ams/user/save', params)

export const remove = params => http.post('/ams/user/remove', params)

export const enable = params => http.post('/ams/user/enable', params)

export const disable = params => http.post('/ams/user/disable', params)

export const saveUserRole = params => http.post('/ams/user/save/role', params)

export const savePassword = params => http.post('/ams/user/save/password', params)

export const resetPassword = id => http.post(`/ams/user/reset/password?id=${id}`)

export const login = params => http.post('/login', params)

export const getUserInfo = () => http.post('/ams/user/info')

export const logout = () => http.post('/logout')
