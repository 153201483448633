<template>
  <div class="dict">
    <span class="dict-dot" v-if="dict.type !== 'none'" :class="dict.type"></span>
    <span>{{ dict.name }}</span>
  </div>
</template>

<script>

import Dictionary from '@/utils/dictionary'

export default {
  name: 'DictText',
  props: {
    code: [String, Number],
    dictName: String
  },
  data () {
    return {
      dict: {}
    }
  },
  watch: {
    code: {
      handler () {
        this.getDict()
      },
      immediate: true
    }
  },
  methods: {
    getDict () {
      const dictList = Dictionary.list(this.dictName)
      this.dict = dictList.find(it => it.code === this.code)

      if (!this.dict || !this.dict.code) {
        this.dict = {
          name: this.code,
          code: this.code,
          type: Dictionary.BADGE_TYPE.none
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">

.dict {
  display: flex;
  align-items: center;
}

.dict-dot {
  height: 6px;
  width: 6px;
  padding: 0;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.dict-dot.success {
  background-color: #67c23a;
}

.dict-dot.danger {
  background-color: #f56c6c;
}

.dict-dot.default {
  background-color: #999999;
}
</style>
