import axios from 'axios'
import { getToken } from './token'
import { TOKEN_NAME } from './contants'

const http = axios.create({
  baseURL: '/api',
  timeout: 60 * 1000
})

/* 过滤请求 */
http.interceptors.request.use((config) => {
  config.headers[TOKEN_NAME] = getToken()
  return config
})

export default http
