<template>
  <div>
    <el-tag
        :key="tag"
        v-for="tag in value"
        closable
        class="my-tag"
        :disable-transitions="false"
        @close="handleClose(tag)">
      {{ tag }}
    </el-tag>
    <el-input
        class="input-new-tag"
        v-if="inputVisible"
        v-model="inputValue"
        ref="saveTagInput"
        size="small"
        @keyup.enter.native="handleInputConfirm"
        @blur="handleInputConfirm"
    >
    </el-input>
    <el-button v-else class="button-new-tag" size="small" @click="showInput" icon="el-icon-plus">添加</el-button>
  </div>
</template>

<script>

export default {
  name: 'DynamicTag',
  props: {
    value: Array
  },
  data () {
    return {
      inputVisible: false,
      inputValue: ''
    }
  },
  methods: {
    handleClose (tag) {
      this.value.splice(this.value.indexOf(tag), 1)
    },
    showInput () {
      this.inputVisible = true
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },
    handleInputConfirm () {
      const inputValue = this.inputValue
      if (inputValue) {
        this.value.push(inputValue)
      }
      this.inputVisible = false
      this.inputValue = ''
    },
    changeValue () {
      this.$emit('input', this.value)
      this.$emit('change', this.value)
    }
  }
}
</script>

<style scoped>
.my-tag {
  margin-right: 10px;
  margin-top: 5px;
  height: 32px;
  line-height: 30px;
}

.button-new-tag {
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 5px;
}

.input-new-tag {
  width: 90px;
  vertical-align: bottom;
  margin-top: 5px;
}
</style>
