import http from '@/utils/http'

// 员工业绩
export const getEmployeeRank = params => http.get('/rpt/user/employee/rank', { params: params })

// 员工业绩汇总
export const getEmployeeRankTotal = params => http.get('/rpt/user/employee/rank/total', { params: params })

// 柱状图数据统计：统计今日、本周、本月、本年 汇总数据
export const getReportChartData = params => http.get('/rpt/user/vip/count', { params: params })

// 用户数统计
export const getUserCountTotalReport = params => http.get('/rpt/user/total', { params: params })
