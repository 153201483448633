<template>
  <el-header height="48px" class="layout-header">
    <div class="header-panel">
      <div class="header-tool">
        <div class="logo" @click="handleGotoHomePage">
          <img src="@/assets/images/logo.svg" class="logo-image">
          <div class="logo-text">素客通</div>
        </div>
      </div>
      <div style="flex: 1 1 0%"></div>
      <div class="header-tool">
        <div class="header-item">
          <el-dropdown trigger="hover" size="large">
            <div class="avatar-wrapper">
              {{ displayName }}({{ orgName }})
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="handleGogoAccountPage" v-if="showAccountLink">我的帐户</el-dropdown-item>
              <el-dropdown-item @click.native="handleChangePassword">修改密码</el-dropdown-item>
              <el-dropdown-item @click.native="handleGotoDocPage">操作手册</el-dropdown-item>
              <el-dropdown-item @click.native="handleLogout">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
  </el-header>
</template>
<script>

import Dictionary from '@/utils/dictionary'

export default {
  name: 'AppHeader',
  computed: {
    displayName () {
      return this.$store.getters.realName || 'Hello, ' + this.$store.getters.userName
    },
    isCollapse () {
      return this.$store.getters.menuIsCollapse
    },
    orgName () {
      return this.$store.getters.orgName
    },
    showAccountLink () {
      return this.$store.state.user.info.userType !== Dictionary.amsUserType.employee.code
    }
  },
  methods: {
    handleGogoAccountPage () {
      this.$router.push('/account')
    },
    handleChangePassword () {
      this.$router.push('/sys/user/password/modify')
    },
    handleLogout () {
      this.$store.dispatch('logout').then(() => {
        this.$store.dispatch('tagsView/delAllViews').then(() => {
          this.$router.push('/login')
        })
      })
    },
    handleMenuSwitch () {
      this.$store.dispatch('toggleMenuIsCollapse')
    },
    handleGotoDocPage () {
      this.$router.push('/doc')
    },
    handleGotoHomePage () {
      this.$router.push('/')
    }
  },
  mounted () {
  }
}
</script>

<style>
.layout-header {
  box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
  transition: width .3s cubic-bezier(.645, .045, .355, 1) !important;
}
</style>

<style lang="less" scoped>

.layout-header {
  background-color: #001529;
  line-height: 48px;
  padding: 0 !important;
  font-size: 14px;
  color: #FFFFFF;

  .header-panel {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 10px;

    .header-tool {
      display: flex;
      align-items: center;
      height: 100%;

      .header-item {
        display: flex;
        align-items: center;
        padding: 0 12px;
        cursor: pointer;
        height: 100%;
        transition: all .3s;

        &:hover {
          background-color: #252a3d;
        }
      }
    }
  }

  .avatar-wrapper {
    color: #FFFFFF;
    font-size: 14px !important;
  }

  .logo {
    display: flex;
    align-items: center;
    padding: 0 12px;
    cursor: pointer;
    height: 100%;
    transition: all .3s;

    .logo-image {
      width: 32px;
      height: 32px;
    }

    .logo-text {
      margin-left: 2px;
      font-size: 16px;
      font-weight: bold;
    }
  }
}

//.el-dropdown {
//  font-size: 14px !important;
//  color: #FFFFFF !important;
//}

</style>
