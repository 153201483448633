import axios from 'axios'
import { MessageBox } from 'element-ui'
import { getToken } from './token'
import { TOKEN_NAME } from './contants'
import store from '@/store'

const http = axios.create({
  baseURL: '/api',
  timeout: 60 * 1000
})

/* 过滤请求 */
http.interceptors.request.use((config) => {
  config.headers[TOKEN_NAME] = getToken()
  return config
})
/* 过滤响应 */
http.interceptors.response.use(
  result => {
    const data = result.data
    if (data.status !== 1) {
      if (data.status === -1) {
        MessageBox.alert(data.message, '提示', {
          customClass: 'my-message-box'
        })
      }
      return Promise.reject(data)
    }
    return data.data
  },
  error => {
    if (error.response) {
      if (error.response.status === 401) {
        store.dispatch('logoutOnly').then(() => {
          location.reload()
        })
      } else if (error.response.status === 403) {
        MessageBox.alert('暂无权限')
      } else if (error.response.status === 500) {
        MessageBox.alert(`服务器异常,请联系管理员：${error.message}`)
      } else {
        // MessageBox.alert(error.message)
      }
    }
    return Promise.reject(error)
  })

export default http
