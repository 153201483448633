import http from '@/utils/http'

export const list = params => http.get('/cms/material/video/list', { params: params })

export const remove = params => http.post('/cms/material/video/remove', params)

export const publish = params => http.post('/cms/material/video/publish', params)

export const out = params => http.post('/cms/material/video/out', params)

export const saveVideo = params => http.post('/cms/material/video/save', params)

export const getVideo = id => http.get(`/cms/material/video/item?id=${id}`)
