import http from '@/utils/http'

export const get = id => http.get(`/user/video/tpl/item?id=${id}`)

export const save = params => http.post('/user/video/tpl/save', params)

export const remove = params => http.post('/user/video/tpl/remove', params)

export const list = params => http.get('/user/video/tpl/list', { params: params })

export const page = params => http.get('/user/video/tpl/page', { params: params })
